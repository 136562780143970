'use strict'

document.addEventListener('DOMContentLoaded', function(e){
    setDateInputs();
    autocompleteGoogle('#search-input-xeni', 'geocode', 'hotel'); //HOTELES
    autocompleteGoogle('#search-atractions-xeni', 'geocode', 'actividad'); //ACTIVIDADES

    autocompleteGoogle('#search-flights-xeni-origen', 'airport', 'aeropuerto', 'origen'); //AEROPUERTO
    autocompleteGoogle('#search-flights-xeni-destino', 'airport', 'aeropuerto', 'destino'); //AEROPUERTO

    autocompleteGoogle('#search-input-xeni-cars', 'establishment', 'auto', '');  //AUTO

    datePickerRange();

    //Evento al hacer clic en el boton openMenuGuests que abre el menu de huespedes
    $("#openMenuGuests").click((function(e) {
        e.preventDefault(),
        e.stopPropagation();
        let menuGuests = $("#menuGuests");
        "none" === menuGuests.css("display") ? menuGuests.css("display", "block") : menuGuests.css("display", "none")
    }));

    //Evento que al hacer clic fuera del menu de huespedes, cierra el menu
    $(document).click((function() {
        $("#menuGuests").css("display", "none")
    }));

    //Si se hace clic dentro del menu, el menu no se oculta
    $("#menuGuests").click((function(e) {
        e.preventDefault(),
        e.stopPropagation()
    }));

    //Boton aplicar: Se oculta el menú de invitados y se actualizan los valores de los campos adult_num, child_num y room_num
    $('#apply').click(function(event) {
        event.preventDefault();
        let menuGuests = $('#menuGuests');
        menuGuests.css('display', 'none');
        let habitaciones = $('.habitacion').length;
        let adultos = $('.adultos').map(function(){return parseInt($(this).val());}).get().reduce((a, b) => a + b, 0);
        let niños = $('.niños').map(function(){return parseInt($(this).val());}).get().reduce((a, b) => a + b, 0);
        let occupanciesArray = [];        
    
        // Actualizar valores de campos
        $('#adult_num').val(adultos);
        $('#child_num').val(niños);
        $('#room_num').val(habitaciones);

        let totalSelectedRooms = $('.habitacion').length;

        let roomIndex = 1; // Inicializa el contador fuera del bucle

        $('.habitacion').each(function() {
            let numOfAdults = parseInt($(`input[name='adultos_${roomIndex}']`).val());
            let numOfChildren = parseInt($(`input[name='niños_${roomIndex}']`).val());
            let childAges = []; // Arreglo para almacenar las edades de los niños de esta habitación

            // Añadir edades de los niños, por defecto 10, al arreglo childAges
            for (let i = 0; i < numOfChildren; i++) {
                childAges.push(10);
            }

            // Agregar los datos de ocupación al arreglo de ocupaciones en el formato deseado
            let occupancy = {
                "numOfRoom": totalSelectedRooms,
                "numOfAdults": numOfAdults,
                "numOfChildren": numOfChildren,
                "childAges": childAges
            };

            occupanciesArray.push(occupancy);

            roomIndex++; // Incrementa el contador al final de cada iteración
        });

        // Convertir el arreglo a una cadena JSON
        let jsonData = JSON.stringify(occupanciesArray);
    
        // Asignar el JSON al input hidden
        $('#occupancies').val(jsonData);

        // Actualizar contenido del botón
        $('#openMenuGuests').html('<i class="fa fa-bed iconMenuGuests" style="font-size:1.7rem;"></i> ' + habitaciones + '&emsp;|&emsp;<i class="fa fa-user iconMenuGuests"></i> ' + adultos + '&emsp;|&emsp;<i class="fa fa-child iconMenuGuests"></i> ' + niños);
    });

    //Boton añadir habitacion: Se agrega una nueva habitación a la lista de habitaciones
    $('#addHabitacion').click(function() {
        let habitacionNum = $('.habitacion').length;
        let maxHabitaciones = 6;
    
        if (habitacionNum < maxHabitaciones) {
            habitacionNum++;
    
            let nuevaHabitacion = $(`
                <div class="habitacion">
                    <div class="row">
                        <div class="col-7">
                            <p class="roomNumber">Habitación <span class="num">${habitacionNum}</span></p>
                        </div>
                        <div class="col-5 text-end">
                            <button class="toggle-habitacion btn-traveler-purple-2">v</button>
                            <button class="eliminar btn-traveler-purple-2">Eliminar</button>
                        </div>
                    </div>
                    <div class="habitacion-content">
                        <div class="row" style="display: flex; justify-content: space-between;">
                            <div class="col d-flex justify-content-center align-items-center">
                                <label for="adultos" class="iconMenuGuests fs-5"><i class="fa fa-user iconMenuGuests"></i> Adultos:</label>
                            </div>
                            <div class="col d-flex justify-content-center align-items-center">
                                <input type="number" class="adultos" name="adultos_${habitacionNum}" min="1" max="4" value="1">
                            </div>
                        </div>
                        <div class="row" style="display: flex; justify-content: space-between;">
                            <div class="col d-flex justify-content-center align-items-center">
                                <label for="niños" class="iconMenuGuests fs-5"><i class="fa fa-child iconMenuGuests"></i> Niños:</label>
                            </div>
                            <div class="col d-flex justify-content-center align-items-center">
                                <input type="number" class="niños" name="niños_${habitacionNum}" min="0" max="4" value="0">
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="habitacionHr">`);
            
            // Agregar la nueva habitación al contenedor
            $('#habitaciones').append(nuevaHabitacion);
        }
    
        if (habitacionNum >= maxHabitaciones) {
            $('#addHabitacion').hide();
        }
    });

    // Función para desplegar/contraer habitaciones
    $('#habitaciones').on('click', '.toggle-habitacion', function(e) {
        e.preventDefault();
        e.stopPropagation();
        
        let habitacionContent = $(this).closest('.habitacion').find('.habitacion-content');
        habitacionContent.slideToggle();
        
        // Cambiar el símbolo del botón
        $(this).text(function(i, text) {
            return text === 'v' ? '^' : 'v';
        });
    });

    //Boton eliminar en habitaciones: Elimina una habitacion 
    $('#habitaciones').on('click', '.eliminar', function() {
        if ($(this).closest('.habitacion').index() > 0) { // No elimina la primera habitación
            $(this).closest('.habitacion').next('.habitacionHr').remove();
            $(this).closest('.habitacion').remove();
            $('.habitacion').each(function(index) {
                $(this).find('.num').text(index + 1);
            });
        }        
        $('#addHabitacion').show();
    });

    // Validar el rango de valores para adultos
    $('#menuGuests').on('input blur', '.adultos', function() {
        let adultos = parseInt($(this).val());
        let minAdultos = parseInt($(this).attr('min'));
        let maxAdultos = parseInt($(this).attr('max'));
        
        // Asegurarse de que el valor esté dentro del rango
        adultos = Math.min(Math.max(adultos, minAdultos), maxAdultos);
        
        // Actualizar el valor del input
        $(this).val(adultos);
    });

    // Validar el rango de valores para niños
    $('#menuGuests').on('input blur', '.niños', function() {
        let niños = parseInt($(this).val());
        let minNiños = parseInt($(this).attr('min'));
        let maxNiños = parseInt($(this).attr('max'));
        
        // Asegurarse de que el valor esté dentro del rango
        niños = Math.min(Math.max(niños, minNiños), maxNiños);
        
        // Actualizar el valor del input
        $(this).val(niños);
    });

});


//MÉTODOS:
/**
 * Función para establecer fechas en un input
 */
function setDateInputs() {
    let fechaSalida = new Date();
    let fechaLlegada = new Date();    

    let fechaLlegadaInput = $("#fechaLlegada").data("fecha-llegada");
    if(fechaLlegadaInput == ''){
        fechaLlegada = new Date();
    } else{
        fechaLlegada = fechaLlegadaInput;
    }

    let fechaSalidaInput = $("#fechaSalida").data("fecha-salida");
    if(fechaSalidaInput == ''){
        fechaSalida = new Date();
        fechaSalida.setDate(fechaLlegada.getDate() + 3);
    } else{
        fechaSalida = fechaSalidaInput;
    }

    $("#fechaLlegada").datepicker({
        showAnim: "slideDown",
        defaultDate: fechaLlegada,
        format: 'dd/mm/yyyy',
        minDate: 0,
        onSelect: function (selectedDate) {
            let selected = new Date(selectedDate);
            $("#fechaSalida").datepicker("option", "minDate", selectedDate);
            $("#fechaSalida").datepicker("setDate", formattedDate);
        }
    });

    $("#fechaSalida").datepicker({
        showAnim: "slideDown",
        defaultDate: fechaSalida,
        minDate: fechaLlegada
    });

    $("#fechaLlegada").datepicker("setDate", fechaLlegada);
    $("#fechaSalida").datepicker("setDate", fechaSalida);
    //console.log('salida: ' + fechaSalida);
    //console.log('salida: ' + fechaLlegada);
}

// FUNCTIONS

function autocompleteGoogle(inputId, type, tipoBusqueda, tipoVuelo){ //tipoVuelo solo se usa para aeropuertos
    let autocomplete = new google.maps.places.Autocomplete((document.querySelector(inputId)), {
        types: [type],
    });
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
        let near_place = autocomplete.getPlace();
        console.log(near_place); //PARA MOSTRAR EN CONSOLA EL RESPONSE COMPLETO DE LA API
        if(tipoBusqueda == 'hotel'){
            document.querySelector('#input-lat').value = near_place.geometry.location.lat();
            document.querySelector('#input-lng').value = near_place.geometry.location.lng();
            document.querySelector('#input-place-id').value = near_place.place_id;
        }
        else if(tipoBusqueda == 'actividad'){
            document.querySelector('#input-lat-atraction').value = near_place.geometry.location.lat();
            document.querySelector('#input-lng-atraction').value = near_place.geometry.location.lng();
        } 
        else if(tipoBusqueda == 'aeropuerto'){
            if (tipoVuelo === 'origen') {
                document.querySelector('#input-lat-flight-origen').value = near_place.geometry.location.lat();
                document.querySelector('#input-lng-flight-origen').value = near_place.geometry.location.lng();
            }
            else if(tipoVuelo === 'destino'){
                document.querySelector('#input-lat-flight-destino').value = near_place.geometry.location.lat();
                document.querySelector('#input-lng-flight-destino').value = near_place.geometry.location.lng();
            }

        }           
            
        if (near_place.address_components && near_place.address_components.length > 0) {
            let zipcodeComponent = near_place.address_components.find(function(component) {
                return component.types.includes('postal_code');
            });

            // Verifica si se encontró el componente del código postal
            if (zipcodeComponent) {
                let zipcode = zipcodeComponent.long_name;
                // console.log('Código Postal:', zipcode);

                // Haz lo que necesites con el código postal
            }

            for (let i = 0; i < near_place.address_components.length; i++) {
                let component = near_place.address_components[i];
                if(tipoBusqueda == 'hotel'){ //PARA BUSCAR HOTELES                    
                    // Verifica si el componente tiene el tipo "country"
                    if (component.types.includes('country')) {
                        let countryCode = component.short_name;
                        // console.log('Código ISO 3166-1 alpha-2 del país:', countryCode);
                        document.querySelector('#input-alpha-code').value = countryCode;
                    }
                }
                if(tipoBusqueda == 'actividad'){ //PARA BUSCAR ACTIVIDADES
                    if (component.types.includes('country')) {
                        // console.log('Código ISO 3166-1 alpha-2 del país:', countryCode);
                        document.querySelector('#input-alpha-code-atraction').value = component.short_name;
                    }
                }
                if(tipoBusqueda == 'aeropuerto'){ //PARA BUSCAR AEROPUERTOS
                    //console.log(component.types);
                    if (component.types.includes('country')){
                        fetch(`https://iatageo.com/getCode/${near_place.geometry.location.lat()}/${near_place.geometry.location.lng()}`)
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error('Error al consumir api IATA');
                                }
                                return response.json();
                            })
                            .then(data => {
                                let inputIATA = tipoVuelo === 'origen' ? document.querySelector('#input-iata-origen') : document.querySelector('#input-iata-destino')
                                // Asignar el código IATA al valor del input
                                inputIATA.value = data.IATA;
                            })
                            .catch(error => {
                                console.error('Error in api iata fetching data:', error);
                                // Manejar errores aquí (por ejemplo, mostrar un mensaje de error)
                            });
                        if(tipoVuelo == "destino"){
                            document.querySelector('#input-country-destino').value = component.long_name;
                        } else{
                            document.querySelector('#input-country-origen').value = component.long_name;
                        }                 
                    }
                    if (component.types.includes('locality')){
                        if(tipoVuelo == "destino"){
                            document.querySelector('#input-city-destino').value = component.long_name;
                            document.querySelector('#input-code-destino').value = component.short_name;
                        } else{
                            document.querySelector('#input-city-origen').value = component.long_name;
                            document.querySelector('#input-code-origen').value = component.short_name;
                        }
                    }
                }
                if(tipoBusqueda == 'auto'){ //PARA BUSCAR AUTOS
                    //console.log(component);
                    console.log(document.querySelector('#input-lat-car').value);
                    document.querySelector('#input-lat-car').value = near_place.geometry.location.lat();
                    document.querySelector('#input-lng-car').value = near_place.geometry.location.lng();      
                    console.log(document.querySelector('#input-lat-car').value);            
                }
            }
        }
    });

}

function datePickerRange() {

    $("#dateRangeSearchHotel").daterangepicker({
      locale: {
        format: "DD-MM-YYYY",
        cancelLabel: "Borrar",
      },
      minDate: moment(),
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(168, "hour"),
      orientation: "top", // <-- and add this
    });
  
    $('#dateRangeSearchHotel').on('cancel.daterangepicker', function(ev, picker) {
      //do something, like clearing an input
      $("#dateRangeSearchHotel").val("");
    });
}


